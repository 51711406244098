import React from "react";
import { Card } from "react-bootstrap";

function SinCard({ cardKey, cardTitle, sinType, ...props }) {
  return (
    <Card key={cardKey}>
      <Card.Body>
        <Card.Title as="div" className="sinCard__title">
          {cardTitle}
        </Card.Title>
        <Card.Text as="div">
          <h3 className="card-sinType">{sinType}</h3>
          {props.children}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default SinCard;
