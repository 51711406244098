import React from "react";
import { Form } from "react-bootstrap";
import { API } from "aws-amplify";
import AsyncCreatableSelect from "react-select/async-creatable";
import "../styles/SinFilterSelect.scss";

export const SinFilterSelect = ({ allSins = false, ...props }) => {
  const tradionalOptions = [
    { value: "Envy", label: "Envy" },
    { value: "Wrath", label: "Wrath" },
    { value: "Gluttony", label: "Gluttony" },
    { value: "Lust", label: "Lust" },
    { value: "Sloth", label: "Sloth" },
    { value: "Greed", label: "Greed" },
    { value: "Pride", label: "Pride" },
  ];

  const modernOptions = [
    {
      value:
        "Minor Sexual Peccadillo (Purloining Used Underwear,Less than 5 Minutes Of Porn Daily etc.)",
      label:
        "Minor Sexual Peccadillo (Purloining Used Underwear,Less than 5 Minutes Of Porn Daily etc.)",
    },
    {
      value: "Seriously Embarrassing Sexual Act (You Know It When You See It)",
      label: "Seriously Embarrassing Sexual Act (You Know It When You See It)",
    },
    {
      value: "Being A Dick (Intended or Inadvertent)",
      label: "Being A Dick (Intended or Inadvertent)",
    },
    {
      value: "Online Stalking (Exceeding 30 Minutes)",
      label: "Online Stalking (Exceeding 30 Minutes)",
    },
    {
      value: "Toilet Abuse (Failure to Flush, Other…)",
      label: "Toilet Abuse (Failure to Flush, Other…)",
    },
    {
      value: "Blame Redirection or Credit",
      label: "Blame Redirection or Credit",
    },
    {
      value: "Pussy Grabbing (See Trump)",
      label: "Pussy Grabbing (See Trump)",
    },
    {
      value: "Road Rage (Including Hand Gestures)",
      label: "Road Rage (Including Hand Gestures)",
    },
    {
      value: "Excessive Blasphemy (Minors Present)",
      label: "Excessive Blasphemy (Minors Present)",
    },
    {
      value: "Tax Evasion (Less Than $5,000)",
      label: "Tax Evasion (Less Than $5,000)",
    },
    {
      value: "Tax Evasion ((Over $5,000) (See Trump)",
      label: "Tax Evasion ((Over $5,000) (See Trump)",
    },
  ];

  const sinOptions = [
    {
      label: "THE TRADITIONAL DEADLY",
      options: tradionalOptions,
    },
    {
      label: "MODERN DAY FAVORITES",
      options: modernOptions,
    },
  ];

  const sinAllOptions = [{ value: "", label: "All sins" }, ...sinOptions];

  const formatGroupLabel = (data) => (
    <div className="selectGroupStyle">
      <span>{data.label}</span>
      <span className="selectGroupStyle-badge">{data.options.length}</span>
    </div>
  );

  const sinList = (filter) => {
    return API.put("absolution", `/searchsins`, {
      body: { filter },
    });
  };

  const filterSins = async (inputValue) => {
    if (inputValue.length > 3) {
      const sinListJson = await sinList(inputValue);
      return sinListJson.Items.map((i) => ({ value: i.mySin, label: i.mySin }));
    }
    return sinOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = async (inputValue) => await filterSins(inputValue);

  return (
    <Form.Group controlId="mysin">
      <AsyncCreatableSelect
        cacheOptions={true}
        defaultOptions={allSins ? sinAllOptions : sinOptions}
        {...props}
        loadOptions={promiseOptions}
        className="sinFilter"
        classNamePrefix="NewSin"
        formatGroupLabel={formatGroupLabel}
      />
    </Form.Group>
  );
};
