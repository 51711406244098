import React, { Fragment, useEffect, useState } from "react";
import { API } from "aws-amplify";
import SinCard from "./SinCard";

const FeaturedSin = ({}) => {
  const [featuredSin, setFeaturedSin] = useState(null);
  const [isLoading, setIsLoading] = useState({});

  useEffect(() => {
    const getFeaturedSin = async () => {
      try {
        const _featuredSin = await API.get("absolution", `/sin-of-the-day`, {});
        if (_featuredSin.length > 0) {
          setFeaturedSin(_featuredSin[0]);
        }
      } catch (e) {
        setFeaturedSin(null);
      }
    };
    setIsLoading(true);
    getFeaturedSin();
    setIsLoading(false);
  }, []);

  const featuredSinSet = featuredSin !== null;

  return (
    <Fragment>
      {featuredSinSet && !isLoading && (
        <div className="featured-sin">
          <SinCard
            cardKey={1}
            cardTitle={`${featuredSin.sinnerName} of ${featuredSin.sinnerLocation}`}
            sinType={featuredSin.mysin}
          >
            <div className="card-sinDesc">
              {featuredSin.content.split("\n").map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </div>
          </SinCard>
        </div>
      )}
    </Fragment>
  );
};

export default FeaturedSin;
