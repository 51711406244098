import React, { useState } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
//import './CheckoutForm.css';

function CheckoutForm({ stripe, totalCost, onSuccess, formStyle = {} }) {
  const [status, setStatus] = useState("default");
  const [cardHolder, setCardHolder] = useState("");
  const [resultMessage, setResultMessage] = useState("Payment Successful");

  const stripeFormStyle = {
    base: {
      lineHeight: "20px",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#999",
      },
      ...formStyle,
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  };

  const submit = async (e) => {
    e.preventDefault();

    setStatus("submitting");

    try {
      let { token } = await stripe.createToken({ name: "Name" });

      let response = await onSuccess(token.id);

      if (response.status) {
        setStatus("complete");
        setResultMessage(response.body);
      } else {
        console.log(response);
        throw new Error("Network response was not ok.");
      }
    } catch (err) {
      console.log(err);
      setStatus("error");
    }
  };

  if (status === "complete") {
    return (
      <div
        className="request__result"
        dangerouslySetInnerHTML={{ __html: resultMessage }}
      />
    );
  }

  return (
    <form className="CheckoutForm" onSubmit={submit}>
      <h4>Would you like to complete the purchase?</h4>
      <Form.Group size="lg" controlId="name">
        <Form.Label>Cardholder&apos;s name</Form.Label>
        <Form.Control
          type="text"
          value={cardHolder}
          onChange={(e) => setCardHolder(e.target.value)}
          placeholder="Name on the card"
        />
      </Form.Group>
      <CardElement style={stripeFormStyle} />
      <LoaderButton
        block
        variant="primary"
        size="lg"
        disabled={status === "submitting"}
        type="submit"
        isLoading={status === "submitting"}
        text="Submit Order"
        loadingText="Submitting..."
      />
      {status === "error" && (
        <div className="CheckoutForm-error">Something went wrong.</div>
      )}
    </form>
  );
}

export default injectStripe(CheckoutForm);
