import React, { Component } from "react";
import { Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { API } from "aws-amplify";
import { RECAPTCHA_V2_SITE_KEY } from "../components/constants";
import AlertMessage from "../components/AlertMessage";

import LoaderButton from "../components/LoaderButton";
import "../styles/Contact.scss";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: null,
      validated: false,
      contactName: "",
      contactEmail: "",
      contactOption: "",
      contactMessage: "",
      captchaValue: null,
      showSuccess: false,
      resultMessage: "",
      resultAlert: null,
      recaptchaRef: React.createRef(),
    };
  }

  validateForm() {
    return (
      this.state.contactName.length > 0 &&
      this.state.contactEmail.length > 0 &&
      this.state.contactMessage.length > 0 &&
      this.state.captchaValue !== null
    );
  }

  async componentDidMount() {}

  captchaChange = (value) => {
    this.setState({
      captchaValue: value,
    });
  };

  handleChange = (event) => {
    if (event.target.type === "checkbox") {
      this.setState({
        [event.target.id]: event.target.checked,
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const form = event.currentTarget;
    //const token = await this.state.recaptchaRef.current.executeAsync();

    if (form.checkValidity() === true) {
      try {
        const contact = {
          contactName: this.state.contactName,
          contactEmail: this.state.contactEmail,
          contactOption: this.state.contactOption,
          contactMessage: this.state.contactMessage,
          captchaValue: this.state.captchaValue,
        };
        const result = await API.post("absolution", "/contactus", {
          body: { ...contact },
        });
        if (result.status) {
          this.setState({
            showSuccess: true,
            resultMessage: result.body,
            resultAlert: "success",
          });
        } else {
          this.setState({ resultMessage: result.body, resultAlert: "danger" });
        }
        //this.props.history.push("/");
      } catch (e) {
        console.log(e);
        this.setState({ isLoading: false });
      }
      this.setState({ validated: true });
    }
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <div className="Contactus">
        <h1>Contact Us</h1>
        <p>We understand sometimes you need to contact us</p>
        <Form
          onSubmit={this.handleSubmit}
          noValidate
          validated={this.state.validated}
        >
          <Form.Group controlId="contactName">
            <Form.Control
              type="text"
              placeholder="Your Name"
              onChange={this.handleChange}
              value={this.state.contactName}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a contact name
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="contactEmail">
            <Form.Control
              type="email"
              placeholder="Email Address"
              onChange={this.handleChange}
              value={this.state.contactEmail}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid contact emai
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="contactOption">
            <Form.Label>Custom select</Form.Label>
            <Form.Control as="select" custom onChange={this.handleChange}>
              <option defaultChecked={true}>General</option>
              <option>Refund (absolution failed)</option>
              <option>Delete a sin</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="contactMessage">
            <Form.Label>Provide Details</Form.Label>
            <Form.Control
              as="textarea"
              onChange={this.handleChange}
              value={this.state.contactMessage}
              required
            />
            <Form.Control.Feedback type="invalid">
              I think you forgot your message...
            </Form.Control.Feedback>
          </Form.Group>
          <ReCAPTCHA
            ref={this.state.recaptchaRef}
            size="normal"
            sitekey={RECAPTCHA_V2_SITE_KEY}
            onChange={this.captchaChange}
          />
          {this.state.showSuccess && (
            <AlertMessage variant={this.state.resultAlert}>
              {this.state.resultMessage}
            </AlertMessage>
          )}
          {!this.state.showSuccess && (
            <LoaderButton
              block
              variant="primary"
              size="lg"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Contact Us"
              loadingText="Contacting..."
            />
          )}
        </Form>
      </div>
    );
  }
}
