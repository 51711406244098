const dev = {
  s3: {
    REGION: "us-east-2",
    BUCKET: "absolution-serverless",
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://whcahvr02d.execute-api.us-east-2.amazonaws.com/prod",
    // URL:"http://localhost:3004/dev",
  },
  pdfGateway: {
    REGION: "us-east-2",
    URL: "https://r7qrzfjq1d.execute-api.us-east-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_NlsTVNsx5",
    APP_CLIENT_ID: "7ss4i4ph9fjoigg7god95bg81p",
    IDENTITY_POOL_ID: "us-east-2:10ec54eb-c0a5-430d-8df5-5c76612a8ac5",
  },
  social: {
    FB: "1116784012029813",
  },
};

const prod = {
  s3: {
    REGION: "us-east-2",
    BUCKET: "absolution-serverless",
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://whcahvr02d.execute-api.us-east-2.amazonaws.com/prod",
  },
  pdfGateway: {
    REGION: "us-east-2",
    URL: "https://r7qrzfjq1d.execute-api.us-east-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_NlsTVNsx5",
    APP_CLIENT_ID: "7ss4i4ph9fjoigg7god95bg81p",
    IDENTITY_POOL_ID: "us-east-2:10ec54eb-c0a5-430d-8df5-5c76612a8ac5",
  },
  social: {
    FB: "1116784012029813",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  // Add common config values here
  ...config,
};
