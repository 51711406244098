import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/typography.scss";
import "./styles/App.scss";
import config from "./config";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPray,
  faCommentAlt,
  faGift,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

export const MenuTile = ({ tileLink, tileClass, tileIcon, tileTitle }) => (
  <div>
    <LinkContainer
      className={tileClass}
      to={tileLink}
      alt={tileTitle}
      title={tileTitle}
    >
      <Nav.Link>
        <div className={`absolution-menu__link-item ${tileClass}`}>
          <FontAwesomeIcon icon={tileIcon} />
          <div className="absolution-menu__title">{tileTitle}</div>
        </div>
        <div className="arrow-down"></div>
      </Nav.Link>
    </LinkContainer>
  </div>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      isCollapsed: true,
    };
  }
  async componentDidMount() {
    this.loadFacebookSDK();

    // try {
    //   await Auth.currentSession();
    //   this.userHasAuthenticated(true);
    //   this.props.history.push("/");
    // } catch (e) {
    //   if (e !== "No current user") {
    //     alert(e);
    //   }
    // }
    try {
      await Auth.currentAuthenticatedUser();
      this.userHasAuthenticated(true);
    } catch (e) {
      if (e !== "not authenticated") {
        alert(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  loadFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: config.social.FB,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v3.1",
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async (event) => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  };

  toggleNavbar = () => this.setState({ isCollapsed: !this.state.isCollapsed });

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
    };
    return (
      !this.state.isAuthenticating && (
        <Fragment>
          <div className="App">
            <div className="absolution-header container">
              <LinkContainer
                className="absolution-header__logo"
                to="/"
                alt="Get Absolved"
                title="Get Absolved"
              >
                <Nav.Link><img src="/images/get-absolved-logo.png" alt="Get Absolved" /></Nav.Link>
              </LinkContainer>
              <Navbar collapseOnSelect={true} expand="md" className="absolution-header__navbar">
                <Navbar.Toggle className="mr-2" />
                <Navbar.Collapse>
                  <Nav>
                    {this.state.isAuthenticated ? (
                      <Fragment>
                        <LinkContainer to="/manage">
                          <Nav.Item>Manage</Nav.Item>
                        </LinkContainer>
                        <LinkContainer to="/">
                          <Nav.Item>My Sins</Nav.Item>
                        </LinkContainer>
                        <LinkContainer to="/faqs">
                          <Nav.Item>Faqs</Nav.Item>
                        </LinkContainer>
                        <Nav.Item onClick={this.handleLogout}>Logout</Nav.Item>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <LinkContainer to="/faqs">
                          <Nav.Item>Faqs</Nav.Item>
                        </LinkContainer>
                        <LinkContainer to="/signup">
                          <Nav.Item>Signup</Nav.Item>
                        </LinkContainer>
                        <LinkContainer to="/login">
                          <Nav.Item>Login</Nav.Item>
                        </LinkContainer>
                      </Fragment>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
            <Container className="absolution-menu">
              <div className="absolution-menu__grid">
                <MenuTile
                  tileClass="nav-new"
                  tileIcon={faPray}
                  tileLink="/sins/new"
                  tileTitle="Get Absolution Now!"
                />
                <MenuTile
                  tileClass="nav-gift"
                  tileIcon={faGift}
                  tileLink="/voucher/buy"
                  tileTitle="Send the gift of absolution"
                />
                <MenuTile
                  tileClass="nav-guarantee"
                  tileIcon={faThumbsUp}
                  tileLink="/guarantee"
                  tileTitle="Absolution Guarantee"
                />
                <MenuTile
                  tileClass="nav-view"
                  tileIcon={faCommentAlt}
                  tileLink="/confessions"
                  tileTitle="Confessions of others"
                />
              </div>
              </Container>
          </div>
          <div className="content">
            <Container className="content-body">
              <Routes childProps={childProps} />
            </Container>
          </div>
          <Navbar className="footer">
            <Nav>
              {this.state.isAuthenticated ? (
                <Fragment>
                  <LinkContainer to="/manage">
                    <Nav.Item>Manage</Nav.Item>
                  </LinkContainer>
                  <LinkContainer to="/">
                    <Nav.Item>My Sins</Nav.Item>
                  </LinkContainer>
                  <Nav.Item onClick={this.handleLogout}>Logout</Nav.Item>
                </Fragment>
              ) : (
                <Fragment>
                  <LinkContainer to="/signup">
                    <Nav.Item>Signup</Nav.Item>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Item>Login</Nav.Item>
                  </LinkContainer>
                </Fragment>
              )}
              <LinkContainer to="/contactus">
                <Nav.Item>Contact Us</Nav.Item>
              </LinkContainer>
              <LinkContainer to="/faqs">
                <Nav.Item>Faqs</Nav.Item>
              </LinkContainer>
            </Nav>
          </Navbar>
        </Fragment>
      )
    );
  }
}
export default withRouter(App);
