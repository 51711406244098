import React, { Component, Fragment } from "react";
import { Form, ListGroup, Button } from "react-bootstrap";
import { API } from "aws-amplify";
import { Elements, StripeProvider } from "react-stripe-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from "react-loading-overlay";
import LoaderButton from "../components/LoaderButton";
import CheckoutForm from "../components/CheckoutForm";
import { Auth } from "aws-amplify";
import { STRIPE_API_KEY } from "../components/constants";
import "../styles/BuyVoucher.scss";

export default class BuyVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: null,
      isLoadingPackages: true,
      packages: [],
      recipient: "",
      recipientName: "",
      senderName: "",
      message: "",
      packageid: null,
      showPaymentForm: false,
      totalCost: 0,
      validated: false,
    };
  }

  validateForm() {
    return this.state.recipient.length > 0 && this.state.packageid;
  }

  async componentDidMount() {
    this.loadPackages();
    const user = await Auth.currentAuthenticatedUser();
    if (user.attributes) {
      this.setState({ senderName: user.attributes.given_name });
    }
  }

  async loadPackages() {
    this.setState({ isLoadingPackages: true });
    try {
      const packages = await this.packages();
      this.setState({
        packages: packages.sort((a, b) =>
          a.packageid.toString().localeCompare(b.packageid.toString())
        ),
      });
    } catch (e) {
      alert(e);
    }
    this.setState({ isLoadingPackages: false });
  }

  packages() {
    return API.get("absolution", `/packages`);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  backToForm = () => {
    this.setState({
      showPaymentForm: false,
    });
  };

  selectPrice = (packageid) => (event) => {
    event.preventDefault();
    this.setState({ packageid: packageid });
  };

  currentCost = () => {
    return (
      this.state.packages.find((e) => e.packageid === this.state.packageid)
        .price +
      (this.state.removesordid ? this.state.extraCharge : 0) +
      (this.state.excludegallery ? this.state.extraCharge : 0)
    );
  };

  renderPackages() {
    return (
      <div className="packages">
        <Form.Group>
          <Form.Label>Choose Absolution duration</Form.Label>
          <p>
            Each Comes With A Valuable Absolution Guarantee and Printable Peace
            Of Mind Absolution Certificate
          </p>
          <LoadingOverlay
            active={this.state.isLoadingPackages}
            spinner
            text="Loading packages..."
          >
            <div
              className={`BuyVoucher__packageList ${
                this.state.isLoadingPackages
                  ? "BuyVoucher__packagesLoading "
                  : ""
              }`}
            >
              <ListGroup flush="true" required>
                {!this.state.isLoadingPackages &&
                  this.renderPackagesList(this.state.packages)}
              </ListGroup>
            </div>
          </LoadingOverlay>
          <Form.Control.Feedback type="invalid">
            Please select a package
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    );
  }

  renderPackagesList(packages) {
    return [].concat(packages).map((sinpackage, i) => {
      if (sinpackage.price > 0) {
        return (
          <ListGroup.Item
            action
            onClick={this.selectPrice(sinpackage.packageid)}
            key={i}
            className={
              (sinpackage.packageid === this.state.packageid
                ? "selected"
                : "") + " absolution-packages"
            }
          >
            <div className="title">{sinpackage.name}</div>
            <div className="price">${sinpackage.price}</div>
            <div className="guarantee">
              {sinpackage.guarantee === 1 ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                ""
              )}
            </div>
            <div className="description">{sinpackage.description}</div>
          </ListGroup.Item>
        );
      } else return <></>;
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const totalSinCost = this.currentCost();
    if (form.checkValidity() === true) {
      this.setState({
        showPaymentForm: true,
        totalCost: totalSinCost.toFixed(2),
      });
    }
    this.setState({ validated: true });
  };

  createVoucher = (voucher) => (stripeToken) => {
    return API.post("absolution", "/voucher", {
      body: { ...voucher, source: stripeToken, amount: this.state.totalCost },
    });
  };

  render() {
    return (
      <div className="BuyVoucher">
        <h1>Help a friend or family member</h1>
        <p>Give the Gift of Absolution</p>
        {!this.state.showPaymentForm && (
          <Form
            onSubmit={this.handleSubmit}
            noValidate
            validated={this.state.validated}
          >
            {this.renderPackages()}
            <Form.Group id="recipient">
              <Form.Label>
                Who's going to get your extraordinary gift?
              </Form.Label>
              <Form.Control
                type="text"
                id="recipientName"
                placeholder="Name of Lucky Individual Who Will Receive Your Wonderful and Thoughtful Gift"
                onChange={this.handleChange}
                value={this.state.recipientName}
                required
              />
              <Form.Control
                type="email"
                placeholder="Email Address (to send Valuable Peace Of Mind Absolution Guarantee)"
                onChange={this.handleChange}
                value={this.state.recipient}
                required
              />
              <Form.Control.Feedback type="invalid">
                Enter all the recipient details
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group id="senderName">
              <Form.Label>Your name</Form.Label>
              <Form.Control
                type="text"
                placeholder="The Very Generous Person Gifting Absolution"
                onChange={this.handleChange}
                value={this.state.senderName}
                required
              />
            </Form.Group>
            <Form.Group id="message">
              <Form.Control
                as="textarea"
                onChange={this.handleChange}
                value={this.state.message}
                placeholder="Add a Fun and Witty Private Message (Optional)"
              />
            </Form.Group>
            <LoaderButton
              block
              variant="primary"
              size="lg"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Send Absolution"
              loadingText="Sending..."
            />
          </Form>
        )}
        {this.state.showPaymentForm && (
          <Fragment>
            <Button
              variant="link"
              onClick={this.backToForm}
              className="BuyVoucher__btnBack"
            >
              <FontAwesomeIcon icon={faChevronLeft} /> Back
            </Button>
            <h3>Cost: ${this.state.totalCost}</h3>
            <StripeProvider apiKey={STRIPE_API_KEY}>
              <Elements>
                <CheckoutForm
                  totalCost={this.totalCost}
                  onSuccess={this.createVoucher({
                    to: this.state.recipientName,
                    emailTo: this.state.recipient,
                    from: "Jason",
                    message: this.state.message,
                  })}
                  formStyle={{
                    backgroundColor: "#fff",
                    color: "#495057",
                    iconColor: "#495057",
                  }}
                />
              </Elements>
            </StripeProvider>
          </Fragment>
        )}
      </div>
    );
  }
}
