import React, { useState } from "react";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";

import "../styles/Faqs.scss";

function Faqs({ ...props }) {
  const faqList = [
    {
      question: "WHAT HAPPENS IF THE ABSOLUTION DOESN’T WORK?",
      answer: (
        <span>
          We are proud to say that none of our Absolutions has ever been shown
          to fail and we stand steadfast and sure behind each of our Absolution
          Guarantees. Of course, in the unlikely and heretofore unprecedented
          event that one of our Absolutions failed, we would immediately and
          without hesitation furnish a full refund.
        </span>
      ),
    },
    {
      question: "WHAT DO I NEED TO PROVIDE TO GET MY REFUND?",
      answer: (
        <span>
          For a full and immediate refund, we would require only that you
          provide proof of Absolution failure. Accepted forms are notarized
          letters from a recognized higher deity or documented testimony from
          any recognized saint or prophet, deceased or extant. Simply send the
          details through to us in the <a href="/contactus">contact us</a> form
        </span>
      ),
    },
    {
      question:
        "HAVE YOU EVER HAD A CASE WHERE YOUR ABSOLUTION WAS SHOWN TO FAIL?",
      answer: (
        <span>
          No. We have never been informed and presented with evidence that our
          Absolution failed.
        </span>
      ),
    },
    {
      question: "IS YOUR ABSOLUTION AS GOOD AS THE ONE I GET FROM THE CHURCH?",
      answer: (
        <span>
          As a matter of policy we do not comment on competitor services or
          their claims.
        </span>
      ),
    },
    {
      question: "WHAT DOES YOUR GUARANTEE COVER?",
      answer: (
        <span>
          Our Absolution Guarantee covers sin annulment for all confessed deeds.
          Please note that while we our Absolution Services can extend into
          eternity we are unable to absolve for any earthly consequences of said
          confessed deeds, which may include police action or other
          jurisdictional consequence. Should you find yourself in a court of law
          or other legal process you may choose to recite our Absolution
          Guarantee. However, we must note that we are unable to provide
          reassurance that this will aid your case and we strongly recommend you
          seek legal advice before embarking on such a course.
        </span>
      ),
    },
    {
      question:
        "WHY WOULD I GET ABSOLUTION FROM YOU RATHER THAN MY LOCAL PRIEST?",
      answer: (
        <span>
          We are proud to point to a number of significant points of difference{" "}
          <i>vis a vis</i> a Catholic Church absolution. Not only does our
          Absolution come with a Full Guarantee and a Certificate to prove it,
          it is also possible to obtain Absolution from the comfort of your home
          or, indeed, while on the go (or run). No longer need you endure a
          chilly damp church or run the risk of COVID infection. Instead, in a
          few easy clicks you can obtain guilt assuaging Absolution in less time
          than it takes to make a cup of tea.
        </span>
      ),
    },
    {
      question:
        "AM I ABLE TO USE PROVE OF ABSOLUTION IN MY UPCOMING COURT CASE?",
      answer: (
        <span>
          You are able to use our Absolution Guarantee in any upcoming court
          case in which you are implicated. However, we must note that we are
          unable to provide reassurance that this will aid your case and we
          strongly recommend you seek legal advice before embarking on such a
          course.
        </span>
      ),
    },
    {
      question:
        "I HAVE A STAG WEEKEND SOON AND WONDERED IF I CAN PREORDER SOME ABSOLUTION, JUST IN CASE?",
      answer: (
        <span>
          Indeed! We have made available a gifting service for these very
          circumstances. Stag weekends often entail need for some emergency
          Absolution so don’t be caught short, click{" "}
          <a href="/sins/new">here</a> to order some in advance.
        </span>
      ),
    },
    {
      question:
        "I HAVE A HEN WEEKEND SOON AND WONDERED IF I CAN PREORDER SOME ABSOLUTION, JUST IN CASE?",
      answer: (
        <span>
          Indeed! We have made available a gifting service for these very
          circumstances. Hen weekends often entail need for some emergency
          Absolution so don’t be caught short, click{" "}
          <a href="/sins/new">here</a> to order some in advance.
        </span>
      ),
    },
    {
      question: "DO YOU OFFER BULK DISCOUNTS OR JOB LOTS ON YOUR ABSOLUTION?",
      answer: (
        <span>
          Since each Absolution gets the same amout of care and attention we are
          unable to offer bulk discounts at this time.
        </span>
      ),
    },
    {
      question: "DO YOU FRANCHISE YOUR SERVICES?",
      answer: (
        <span>
          We do not franchise our services or otherwise make them available
          elsewhere. Get-Absolved is the only and original online Absolution
          Service that comes with a Full Guarantee. Do not be fooled by cheap
          imitators!
        </span>
      ),
    },
    {
      question: "DO YOUR GUARANTEES WORK IN OTHER COUNTRIES?",
      answer: (
        <span>
          Our Absolution is universal, although we acknowledge service coverage
          issues in parts of Mississippi.
        </span>
      ),
    },
  ];

  const [activeEventKey, setActiveEventKey] = useState(0);

  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    // const currentEventKey = activeEventKey;

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey) && setActiveEventKey(eventKey)
    );

    //const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Accordion.Toggle
        as={Card.Header}
        variant="link"
        eventKey
        onClick={decoratedOnClick}
      >
        {children}
      </Accordion.Toggle>
    );
  };

  const faqControlList = (qaList) => {
    return (
      <Accordion>
        {qaList.map((qaPair, i) => (
          <Card key={i}>
            <ContextAwareToggle eventKey={i}>
              {qaPair.question}
            </ContextAwareToggle>
            <Accordion.Collapse eventKey={i}>
              <Card.Body>{qaPair.answer}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    );
  };

  return (
    <div className="Faqs">
      <h1>Get Absolved FAQ's</h1>
      {faqControlList(faqList)}
    </div>
  );
}

export default Faqs;
