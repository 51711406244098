import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { API } from "aws-amplify";
import LoadingOverlay from "react-loading-overlay";
import { SinFilterSelect } from "../components/SinFilterSelect";
import LoaderButton from "../components/LoaderButton";
import "../styles/Confessions.scss";
import CardList from "../components/CardList";

export default class Confession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: null,
      mysin: "",
      sins: [],
      lastEvaluatedKey: "",
    };
  }

  filterSins = (e) => {
    this.setState({ mysin: e.value, sins: [], lastEvaluatedKey: "" }, () =>
      this.loadSins()
    );
  };

  async componentDidMount() {
    // if (!this.props.isAuthenticated) {
    //   return;
    // }
    this.loadSins();
  }

  async loadSins() {
    this.setState({ isLoading: true });
    try {
      const sins = await this.sins();
      this.setState({
        sins: this.state.sins.concat(sins.Items),
        lastEvaluatedKey: sins.LastEvaluatedKey,
      });
    } catch (e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }

  sins() {
    return API.put("absolution", `/topsins/${this.state.mysin}`, {
      body: this.state.lastEvaluatedKey,
    });
  }

  renderSinsList(sins) {
    if (sins.length > 0) {
      return <CardList sinList={sins} />;
    }
  }

  getNextPage() {
    this.loadSins();
    this.renderSinsList(this.state.sins);
  }

  render() {
    return (
      <div className="confessions">
        <h1>SEE SINS OF OTHER HAPPY ABSOLVEES</h1>
        <Form.Group controlId="formControlsSelect">
          <Form.Label>Filter sins</Form.Label>
          <SinFilterSelect
            allSins={true}
            onChange={this.filterSins}
            isValidNewOption={() => null}
            placeholder="Select Sin or Type in to get a Match"
          />
        </Form.Group>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text="Loading sins..."
        >
          <div
            className={`confessions__list ${
              this.state.isLoading ? "confessions__loading " : ""
            }`}
          >
            {this.renderSinsList(this.state.sins)}
          </div>
          <div>
            {this.state.lastEvaluatedKey && (
              <LoaderButton
                block
                variant="primary"
                size="lg"
                disabled={false}
                type="submit"
                isLoading={this.state.isLoading}
                text="More"
                loadingText="Loading..."
                onClick={() => this.getNextPage()}
              />
            )}
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}
