import React from "react";
import { Alert } from "react-bootstrap";

function AlertMessage({ variant = "", ...props }) {
  const showAlert = variant.length > 0;
  return (
    showAlert && (
      <Alert variant={variant} transition={null}>
        {props.children}
      </Alert>
    )
  );
}

export default AlertMessage;
