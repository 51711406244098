import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import AlertMessage from "../components/AlertMessage";
import "./Signup.css";

function Manage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    variant: "",
    message: "",
  });
  const [userDetails, setUserDetails] = useState({
    email: "",
    given_name: "",
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    setUserDetails({ ...userDetails, [event.target.id]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const emailChanging = user.attributes
      ? user.attributes.email !== userDetails.email
      : false;

    const result = await Auth.updateUserAttributes(user, {
      email: userDetails.email,
      given_name: userDetails.given_name,
    });
    if (result === "SUCCESS") {
      if (emailChanging) {
        setIsConfirming(true);
        setAlertMessage({
          variant: "info",
          message:
            "Details successfully updated however you will have received a code via email to confirm the email change",
        });
      } else {
        setAlertMessage({
          variant: "success",
          message: "Details updated",
        });
      }
    }
    const changePassword =
      userDetails.currentPassword.length &&
      userDetails.password.length &&
      userDetails.password === userDetails.confirmPassword;
    if (changePassword) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            userDetails.currentPassword,
            userDetails.password
          );
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
    }
    setIsLoading(false);
  };

  const handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        "email",
        userDetails.confirmationCode
      );
      setAlertMessage({
        variant: "success",
        message: "Email updated",
      });
      setIsConfirming(false);
    } catch (e) {
      setAlertMessage({
        variant: "danger",
        message: e.message,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getAuthUserDetails = async () => {
      //const user = await Auth.currentUserInfo();
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const attributes = user.attributes;
        setUserDetails({ ...userDetails, ...attributes });
      }
    };
    setIsLoading(true);
    getAuthUserDetails();
    setIsLoading(false);
  }, []);
  return (
    <div className="Signup">
      {isConfirming ? (
        <form onSubmit={handleConfirmationSubmit}>
          <Form.Group controlId="confirmationCode" size="lg">
            <Form.Label>Confirmation Code</Form.Label>
            <Form.Control
              autoFocus
              type="tel"
              value={userDetails.confirmationCode}
              onChange={handleChange}
            />
            <AlertMessage variant={alertMessage.variant}>
              {alertMessage.message}
            </AlertMessage>
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            //disabled={!this.validateConfirmationForm()}
            type="submit"
            isLoading={isLoading}
            text="Verify"
            loadingText="Verifying..."
          />
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          <Form.Group controlId="email" size="lg">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={userDetails.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="given_name" size="lg">
            <Form.Label>Your name</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              value={userDetails.given_name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="currentPassword" size="lg">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              // value={this.state.password}
              onChange={handleChange}
              type="password"
            />
          </Form.Group>
          <Form.Group controlId="password" size="lg">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              // value={this.state.password}
              onChange={handleChange}
              type="password"
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword" size="lg">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              // value={this.state.confirmPassword}
              onChange={handleChange}
              type="password"
            />
          </Form.Group>
          <AlertMessage variant={alertMessage.variant}>
            {alertMessage.message}
          </AlertMessage>
          <LoaderButton
            block
            size="lg"
            // disabled={!this.validateForm()}
            type="submit"
            isLoading={isLoading}
            text="Update"
            loadingText="Updating..."
          />
        </form>
      )}
    </div>
  );
}

export default Manage;
