import React, { Component } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
//import FacebookButton from "../components/FacebookButton";
import IntroText from "../components/IntroText";
import AlertMessage from "../components/AlertMessage";
import "../styles/Login.scss";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      resultMessage: "",
      resultAlert: "",
    };
  }
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ resultMessage: e.message, resultAlert: "danger" });
      this.setState({ isLoading: false });
    }
  };

  handleFbLogin = () => {
    this.props.userHasAuthenticated(true);
  };

  render() {
    return (
      <div className="Login">
        <IntroText showLogin={false} />
        {/* <FacebookButton onLogin={this.handleFbLogin} /> */}
        <form onSubmit={this.handleSubmit}>
          <Form.Group controlId="email" size="lg">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group controlId="password" size="lg">
            <Form.Label>Password</Form.Label>
            <Form.Control
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </Form.Group>
          <AlertMessage variant={this.state.resultAlert}>
            {this.state.resultMessage}
          </AlertMessage>
          <LoaderButton
            block
            size="lg"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in..."
          >
            Login
          </LoaderButton>
          <Link to="/login/reset" className="Login__forgot-pass">
            Forgot password?
          </Link>
        </form>
      </div>
    );
  }
}
