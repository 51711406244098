import React, { Component } from "react";
import { API } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "../styles/Sins.scss";

export default class Sins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sin: null,
      content: "",
    };

    this.state = {
      isLoading: null,
      isDeleting: null,
      sin: null,
      content: "",
    };
  }

  async componentDidMount() {
    try {
      const sin = await this.getSin();
      const { content } = sin;
      this.setState({
        sin,
        content,
      });
    } catch (e) {
      alert(e);
    }
  }

  getSin() {
    return API.get("absolution", `/sins/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.content.length > 0;
  }

  formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleFileChange = (event) => {
    this.file = event.target.files[0];
  };

  saveSin(sin) {
    return API.put("absolution", `/sins/${this.props.match.params.id}`, {
      body: sin,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });
    try {
      await this.saveSin({
        content: this.state.content,
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  deleteSin() {
    return API.del("absolution", `/sins/${this.props.match.params.id}`);
  }

  handleDelete = async (event) => {
    event.preventDefault();
    const confirmed = window.confirm(
      "Are you sure you want to delete this sin?"
    );
    if (!confirmed) {
      return;
    }
    this.setState({ isDeleting: true });

    try {
      await this.deleteSin();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  };

  render() {
    return (
      <div className="sins">
        {this.state.sin && (
          <form onSubmit={this.handleSubmit}>
            <Form.Group controlId="content">
              <Form.Control
                onChange={this.handleChange}
                value={this.state.content}
                componentClass="textarea"
              />
            </Form.Group>
            <LoaderButton
              block
              variant="primary"
              size="lg"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
            <LoaderButton
              block
              variant="danger"
              size="lg"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Delete"
              loadingText="Deleting…"
            />
          </form>
        )}
      </div>
    );
  }
}
