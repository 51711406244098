import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Home from "./containers/Home";
import NewSin from "./containers/NewSin";
import Sins from "./containers/Sins";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ResetPassword from "./containers/ResetPassword";
import Confessions from "./containers/Confessions";
import BuyVoucher from "./containers/BuyVoucher";
import Guarantee from "./containers/Guarantee";
import Manage from "./containers/Manage";
import Faqs from "./containers/Faqs";
import Contact from "./containers/Contact";
import { TransitionGroup, CSSTransition } from "react-transition-group";

export default ({ childProps }) => {
  const myLocation = useLocation();
  return (
    <div className="contentWrapper">
      <TransitionGroup className="transition-group" mode={"out-in"}>
        <CSSTransition
          key={myLocation.key}
          timeout={{ enter: 1000, exit: 300 }}
          classNames="fade"
        >
          <Switch>
            <AppliedRoute path="/" exact component={Home} props={childProps} />
            <AuthenticatedRoute
              path="/sins/new"
              exact
              component={NewSin}
              props={childProps}
            />
            <AuthenticatedRoute
              path="/voucher/buy"
              exact
              component={BuyVoucher}
              props={childProps}
            />
            <AppliedRoute
              path="/guarantee"
              exact
              component={Guarantee}
              props={childProps}
            />
            <AppliedRoute
              path="/faqs"
              exact
              component={Faqs}
              props={childProps}
            />
            <AppliedRoute
              path="/contactus"
              exact
              component={Contact}
              props={childProps}
            />
            <AuthenticatedRoute
              path="/sins/:id"
              exact
              component={Sins}
              props={childProps}
            />
            <UnauthenticatedRoute
              path="/login"
              exact
              component={Login}
              props={childProps}
            />
            <UnauthenticatedRoute
              path="/signup"
              exact
              component={Signup}
              props={childProps}
            />
            <UnauthenticatedRoute
              path="/login/reset"
              exact
              component={ResetPassword}
              props={childProps}
            />
            <AuthenticatedRoute
              path="/manage"
              exact
              component={Manage}
              props={childProps}
            />
            <AppliedRoute
              path="/confessions"
              exact
              component={Confessions}
              props={childProps}
            />
            {/* Finally, catch all unmatched routes */}
            <Route component={NotFound} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};
