import React, { useState, Fragment } from "react";
import { Form, Spinner } from "react-bootstrap";
import { API } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import "./VoucherCheck.scss";

export const VoucherCheck = ({ voucherDetails = () => {} }) => {
  const [voucherCode, setVoucherCode] = useState("");
  const [checkingCode, setCheckingCode] = useState(false);
  const [voucherValue, setVoucherValue] = useState(0);
  const [lastCheckedCode, setLastCheckedCode] = useState("");
  const [validVoucher, setValidVoucher] = useState(false);

  const checkVoucher = async (voucherCode) => {
    if (voucherCode !== lastCheckedCode && voucherCode !== "") {
      setCheckingCode(true);
      try {
        const voucher = await API.put("absolution", `/voucher-check`, {
          body: { voucherCode: voucherCode },
        });
        if (voucher.length) {
          setVoucherValue(voucher[0].voucherValue);
          setValidVoucher(true);
          console.log(voucherDetails);
          voucherDetails({
            voucherCode: voucherCode,
            voucherValue: voucher[0].voucherValue,
          });
        } else {
          setVoucherValue(0);
          setValidVoucher(false);
        }
        setCheckingCode(false);
        setLastCheckedCode(voucherCode);
      } catch (e) {
        // TODO: nicer error
        console.log(e);
        setValidVoucher(false);
        setCheckingCode(false);
      }
    }
  };
  //FORGIVE-IaGKsvsyqPbhdMo-ME;

  const isVoucherCheckedAndValid =
    voucherCode && !checkingCode ? (
      validVoucher ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <FontAwesomeIcon icon={faTimes} />
      )
    ) : (
      <></>
    );

  return (
    <Fragment>
      <Form.Group controlId="content" className="voucher-check">
        <Form.Label>Voucher Details</Form.Label>
        <Form.Control
          type="text"
          onChange={(e) => setVoucherCode(e.target.value)}
          value={voucherCode}
          onBlur={() => checkVoucher(voucherCode)}
          placeholder="Enter voucher code"
        />
        {checkingCode && (
          <Spinner className="voucher-check__spinner" animation="grow" />
        )}
        {isVoucherCheckedAndValid}
      </Form.Group>
      {validVoucher && <h3>Voucher value: {voucherValue}</h3>}
    </Fragment>
  );
};
