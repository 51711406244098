import React, { useState, Fragment } from "react";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import SinCard from "./SinCard";
import { Auth } from "aws-amplify";
import config from "../config";
import "../styles/CardList.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

function CardList({ sinList, getPdf = false, ...props }) {
  const [certificate, setCertificate] = useState("");
  const [open, setOpen] = React.useState(false);

  const isSinList = sinList.length > 0;

  const downloadSin = async (sinId, statusFunc) => {
    statusFunc(true);
    const currentUser = await Auth.currentUserInfo();
    const url = `${config.pdfGateway.URL}/download-guarantee/${sinId.sinId}/${currentUser.id}`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "guarantee.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();
    statusFunc(false);
  };

  const viewSin = async (sinId, statusFunc) => {
    const result = await API.get(
      "absolution-pdf",
      `/view-guarantee/${sinId.sinId}`
    );
    console.log("----New result----");
    setCertificate(result);
    statusFunc(false);
    setOpen(true);
    return result;
  };

  const CertificateButtons = (sinId) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isViewing, setIsViewing] = useState(false);

    return (
      <Fragment>
        <LoaderButton
          isLoading={isDownloading}
          text={"Download Guarantee"}
          loadingText={"Downloading..."}
          variant="link"
          onClick={() => {
            downloadSin(sinId, setIsDownloading);
          }}
        />
        <LoaderButton
          isLoading={isViewing}
          text={"View Guarantee"}
          loadingText={"Viewing..."}
          variant="link"
          onClick={() => {
            viewSin(sinId, setIsViewing);
          }}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      {isSinList &&
        sinList.map((sin, i) => (
          <SinCard
            key={i}
            cardKey={i}
            cardTitle={`${sin.sinnerName} of ${sin.sinnerLocation}`}
            sinType={sin.mysin}
          >
            <div className="card-sinDesc">
              {sin.content.split("\n").map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </div>
            {getPdf && (
              <div className="cardlist_downloadPdf">
                <CertificateButtons sinId={sin.sinid} />
              </div>
            )}
          </SinCard>
        ))}
      <Modal open={open} onClose={() => setOpen(false)}>
        <iframe
          title="Absolution Guarantee"
          srcDoc={certificate}
          className="cardlist_guaranteeModal"
        />
      </Modal>
    </Fragment>
  );
}

export default CardList;
