import React, { Component, Fragment } from "react";
import { ListGroup, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import IntroText from "../components/IntroText";
import LoadingOverlay from "react-loading-overlay";
import CardList from "../components/CardList";
import SinCard from "../components/SinCard";
import FeaturedSin from "../components/FeaturedSin";
import "../styles/Home.scss";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      sins: [],
    };
  }
  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      const sins = await this.sins();
      this.setState({ sins });
    } catch (e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }

  sins() {
    return API.get("absolution", "/sins");
  }

  renderSinsList(sins) {
    return (
      <Fragment>
        <SinCard
          key={9999}
          cardKey={9999}
          cardTitle={`You of right now`}
          sinType="Not seeking Absolution"
        >
          <LinkContainer key="new" to="/sins/new">
            <Nav.Link>
              <h1>
                <b>{"\uFF0B"}</b> Get Absolved
              </h1>
            </Nav.Link>
          </LinkContainer>
        </SinCard>
        <CardList sinList={sins} getPdf={true} />
      </Fragment>
    );
  }

  renderSins() {
    return (
      <div className="sins">
        <h1>Your Sins</h1>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text="Loading sins..."
        >
          <ListGroup
            className={`Home__list ${
              this.state.isLoading ? "Home__loading " : ""
            }`}
          >
            {!this.state.isLoading && this.renderSinsList(this.state.sins)}
          </ListGroup>
        </LoadingOverlay>
      </div>
    );
  }

  isAuthenticated = () => this.props.isAuthenticated;

  render() {
    return (
      <div className="Home">
        <div className="lander">
          <IntroText showLogin={!this.isAuthenticated()} />
          <FeaturedSin />
        </div>
        {this.isAuthenticated() && this.renderSins()}
      </div>
    );
  }
}
