import React, { Component, Fragment } from "react";
import {
  Form,
  ListGroup,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import { Elements, StripeProvider } from "react-stripe-elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronLeft,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import LoadingOverlay from "react-loading-overlay";
import LoaderButton from "../components/LoaderButton";
import CheckoutForm from "../components/CheckoutForm";
import { VoucherCheck } from "../components/VoucherCheck";
import { SinFilterSelect } from "../components/SinFilterSelect";
import { STRIPE_API_KEY } from "../components/constants";
import "../styles/NewSin.scss";

export default class NewSin extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      isLoading: null,
      isLoadingPackages: true,
      validated: false,
      packages: [],
      content: "",
      mysin: "",
      sinnerName: "",
      sinnerLocation: "",
      packageid: null,
      excludegallery: false,
      removesordid: false,
      showPaymentForm: false,
      showSuccess: false,
      resultMessage: "",
      totalCost: 0,
      extraCharge: 0.99,
      voucherDetails: {
        voucherCode: null,
        voucherValue: 0,
      },
    };
    this.sinOptions = [
      { value: "Envy", label: "Envy" },
      { value: "Wrath", label: "Wrath" },
      { value: "Gluttony", label: "Gluttony" },
      { value: "Lust", label: "Lust" },
      { value: "Sloth", label: "Sloth" },
      { value: "Greed", label: "Greed" },
      { value: "Pride", label: "Pride" },
    ];
    this.popOverStyle = {
      padding: "4px",
      backgroundColor: "black",
      fontSize: "12px",
      textAlign: "left",
    };
  }

  validateForm() {
    return (
      //this.state.content.length > 0 &&
      this.state.mysin.length > 0 && this.state.packageid
    );
  }

  async componentDidMount() {
    this.loadPackages();
  }

  async loadPackages() {
    this.setState({ isLoadingPackages: true });
    try {
      const packages = await this.packages();
      this.setState({
        packages: packages.sort((a, b) =>
          a.packageid.toString().localeCompare(b.packageid.toString())
        ),
      });
    } catch (e) {
      alert(e);
    }
    this.setState({ isLoadingPackages: false });
  }

  currentCost = () => {
    return (
      this.state.packages.find((e) => e.packageid === this.state.packageid)
        .price +
      (this.state.removesordid ? this.state.extraCharge : 0) +
      (this.state.excludegallery ? this.state.extraCharge : 0)
    );
  };
  packages() {
    return API.get("absolution", `/packages`);
  }

  handleChange = (event) => {
    if (event.target.type === "checkbox") {
      this.setState({
        [event.target.id]: event.target.checked,
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handleSinChange = (event) => {
    this.setState({
      mysin: event.value,
    });
  };

  selectPrice = (packageid) => (event) => {
    event.preventDefault();
    this.setState({ packageid: packageid });
  };

  getVoucher = (voucherObject) => {
    return this.setState({ voucherDetails: voucherObject });
  };

  backToForm = () => {
    this.setState({
      showPaymentForm: false,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === true) {
      const totalSinCost = this.currentCost();
      const isPaymentNeeded =
        totalSinCost > 0 &&
        totalSinCost > this.state.voucherDetails.voucherValue;
      if (isPaymentNeeded) {
        this.setState({
          showPaymentForm: true,
          totalCost: (
            totalSinCost - this.state.voucherDetails.voucherValue
          ).toFixed(2),
        });
      } else {
        this.setState({
          isLoading: true,
        });
        try {
          const sin = {
            content: this.state.content,
            mysin: this.state.mysin,
            package: this.state.packageid,
            removesordid: this.state.removesordid,
            excludegallery: this.state.excludegallery,
            voucherCode: this.state.voucherDetails.voucherCode,
            sinnerName: this.state.sinnerName,
            sinnerLocation: this.state.sinnerLocation,
          };
          const result = await this.createSin(sin)("nocharge");
          if (result.status) {
            this.setState({ showSuccess: true, resultMessage: result.body });
          }
          //this.props.history.push("/");
        } catch (e) {
          console.log(e);
          this.setState({ isLoading: false });
        }
      }
    }
    this.setState({ validated: true });
  };

  createSin = (sin) => async (stripeToken) => {
    return API.post("absolution", "/sins", {
      body: { ...sin, source: stripeToken },
    });
  };

  renderPackages() {
    return (
      <div className="packages">
        <Form.Group>
          <Form.Label>Absolution duration</Form.Label>
          <LoadingOverlay
            active={this.state.isLoadingPackages}
            spinner
            text="Loading packages..."
          >
            <div
              className={`NewSin__packageList ${
                this.state.isLoadingPackages ? "NewSin__packagesLoading " : ""
              }`}
            >
              <ListGroup flush="true">
                {!this.state.isLoadingPackages &&
                  this.renderPackagesList(this.state.packages)}
              </ListGroup>
            </div>
          </LoadingOverlay>
        </Form.Group>
      </div>
    );
  }

  renderPackagesList(packages) {
    return [].concat(packages).map((sinpackage, i) => (
      <ListGroup.Item
        action
        onClick={this.selectPrice(sinpackage.packageid)}
        key={i}
        className={
          (sinpackage.packageid === this.state.packageid ? "selected" : "") +
          " absolution-packages"
        }
      >
        <div className="title">{sinpackage.name}</div>
        <div className="price">${sinpackage.price}</div>
        <div className="guarantee">
          {sinpackage.guarantee === 1 ? <FontAwesomeIcon icon={faCheck} /> : ""}
        </div>
        <div className="description">{sinpackage.description}</div>
      </ListGroup.Item>
    ));
  }

  render() {
    return (
      <div className="NewSin">
        <h1>Get Absolved</h1>
        <p>Enter Details To Get Absolution For Yourself Or Friend</p>
        {!this.state.showPaymentForm && !this.state.showSuccess && (
          <Form
            onSubmit={this.handleSubmit}
            noValidate
            validated={this.state.validated}
          >
            <Form.Group controlId="sinnerName">
              <Form.Control
                type="text"
                placeholder="Name of Person in Need of Absolution"
                onChange={this.handleChange}
                value={this.state.sinnerName}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter the sinners name. Can be just a nickname
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="sinnerLocation">
              <Form.Control
                type="text"
                placeholder="Approximate Location of Absolvee"
                onChange={this.handleChange}
                value={this.state.sinnerLocation}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter the sinners general location. So we don't confuse
                them with someone else
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="sinnerLocation">
              <Form.Label>
                Sin title{" "}
                <OverlayTrigger
                  trigger="hover"
                  placement="auto"
                  overlay={
                    <Tooltip id="content-help" style={this.popOverStyle}>
                      <span>
                        Choose one of the Traditional Seven Deadly Sins, try a
                        Modern Day Classic or Create Your Very Own
                      </span>
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
              </Form.Label>
              <SinFilterSelect
                onChange={this.handleSinChange}
                defaultInputValue={this.state.mysin}
                placeholder="Select Relevant Sin Or Type In Your Own Variation"
              />
            </Form.Group>
            <Form.Group controlId="content">
              <Form.Label>
                Provide Details{" "}
                <OverlayTrigger
                  trigger="hover"
                  placement="right"
                  overlay={
                    <Tooltip id="content-help" style={this.popOverStyle}>
                      In as few or as many words as needed, provide details of
                      the transgression. Greater specificity means our
                      Absolution Service will operate with enhanced accuracy.
                      <br />
                      We understand these details may be incriminating,
                      embarrassing or downright unseemly but we must insist on a
                      full confessional disclosure. Should you choose for
                      greater reassurance and peace of mind to receive a
                      Certificate Of Absolution Guarantee, you will have the
                      opportunity to exclude any and all compromising details
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                as="textarea"
                onChange={this.handleChange}
                value={this.state.content}
                required
                placeholder="Enter your sin details"
              />
              <Form.Control.Feedback type="invalid">
                Don't forget the sin. That's the good part.
              </Form.Control.Feedback>
            </Form.Group>
            {this.renderPackages()}
            <Form.Group controlId="excludegallery">
              <Form.Check
                type="switch"
                onChange={this.handleChange}
                id="excludegallery"
                label="Exclude from Public Gallery"
                checked={this.state.excludegallery}
              />
            </Form.Group>
            <Form.Group controlId="removesordid">
              <Form.Check
                type="switch"
                onChange={this.handleChange}
                id="removesordid"
                label="Remove sordid details from certificate"
                checked={this.state.removesordid}
              />
            </Form.Group>
            <VoucherCheck voucherDetails={this.getVoucher} />
            <LoaderButton
              block
              variant="primary"
              size="lg"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Get Absolved"
              loadingText="Creating..."
            />
          </Form>
        )}
        {this.state.showPaymentForm && (
          <Fragment>
            <Button
              variant="link"
              onClick={this.backToForm}
              className="NewSin__btnBack"
            >
              <FontAwesomeIcon icon={faChevronLeft} /> Back
            </Button>
            {!this.state.showSuccess && <h3>Cost: ${this.state.totalCost}</h3>}
            <StripeProvider apiKey={STRIPE_API_KEY}>
              <Elements>
                <CheckoutForm
                  totalCost={this.totalCost}
                  onSuccess={this.createSin({
                    content: this.state.content,
                    mysin: this.state.mysin,
                    package: this.state.packageid,
                    removesordid: this.state.removesordid === "on" ? 1 : 0,
                    excludegallery: this.state.excludegallery === "on" ? 1 : 0,
                    voucherCode: this.state.voucherDetails.voucherCode,
                    sinnerName: this.state.sinnerName,
                    sinnerLocation: this.state.sinnerLocation,
                  })}
                  formStyle={{ backgroundColor: "#fff", color: "#08476d" }}
                />
              </Elements>
            </StripeProvider>
          </Fragment>
        )}
        {this.state.showSuccess && (
          <div
            className="request__result"
            dangerouslySetInnerHTML={{ __html: this.state.resultMessage }}
          />
        )}
      </div>
    );
  }
}
