import React, { Fragment } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";

function IntroText({ showLogin = true, ...props }) {
  return (
    <div className="intro-text">
      <h1>Get Absolved</h1>
      <p className="introText">
        We all make mistakes. Some are serious, some minor. But they can all
        weigh on us. But they no longer have to!
      </p>

      <p className="introText">
        With Get-absolved and in just a few clicks you can get absolution for
        yourself or a troubled friend. And best of all, it’s fully guaranteed!
      </p>
      {showLogin && (
        <Fragment>
          <p className="introText">Login and live guilt-free!</p>
          <p>
            <LinkContainer key="login" to="/login">
              <Nav.Link>Log in to Get-absolved</Nav.Link>
            </LinkContainer>
          </p>
        </Fragment>
      )}
    </div>
  );
}

export default IntroText;
